.icon-filter--white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(3631%) hue-rotate(222deg) brightness(121%) contrast(100%);
}

.button-outlined.form-footer-btn {
    margin-right: 1rem;
    min-width: 110px;
    font-size: 0.813rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    height: 3rem;
    border: solid 1px #2362A2;
    padding-right: 1.75em;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;
}

.button-outlined:hover {
    background-color: var(--gov-color-primary-200) !important;
}

.save_link.disabled {
    cursor: default;
    pointer-events: none;
}

.button-solid.form-header-btn {
    font-size: 0.813rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    height: 39px;
    border: solid 1px #2362A2;
    padding: 8px 12px;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFF;

    &.form-footer-btn {
        height: 3rem;
        border: none;
    }
    
    &.disabled {
        background-color: #B5D9F3;
        border: none;
        pointer-events: none;
    }
}

.form-footer-btn-text_first {
    font-size: 0.938rem;
    font-weight: 500;
    line-height: 1.464rem;
    letter-spacing: 0.018rem;
    color: #6FBD2C;
}

.form-footer-btn-text_second {
    font-size: 0.938rem;
    font-weight: 500;
    line-height: 1.464rem;
    letter-spacing: 0.018rem;
    color: black;
}

.form-footer-btn-text_main {
    font-size: 0.938rem;
    font-weight: 500;
    line-height: 1.464rem;
    letter-spacing: 0.018rem;
    text-decoration: underline;
    
    &.enabled {
        color: #2362A2;
        cursor: pointer;
    }
    
    &.disabled {
        color: #cccccc;
        cursor: not-allowed;
    }
}

.loader-header-text {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.015rem;
}

.loader-header-percentage {
    margin-left: 2.25rem;
    margin-bottom: 1rem;
    font-size: 0.938rem;
    font-weight: 500;
    line-height: 1.464rem;
    letter-spacing: 0.018rem;
}

.button-solid:hover {
    background-color: var(--gov-color-primary-700) !important;
}

.welcome-section {
    background-color: #FFFFFF;
    color: white;
    height: 85vh;
    min-height: 90px;
}

.push-items-down {
    margin-top: 92px;
}

.disable-scroll {
    display: none;
}

.base-width {
    width: 100%;
}

.textarea-with-count-container {
    z-index: 6;
}

.textarea-with-count {
    z-index: 5;
}

.textarea-counter {
    padding: 4px;
    position: absolute;
    top: 70%;
    right: 2.2%;
    /* z-index: 90 !important; */
}

.textarea-couner-text {
    z-index: 100 !important;
}

.header__detail-typography {
    font-size: 1.188rem;
    font-weight: 600;
    line-height: 1.781rem;
    letter-spacing: 0.01em;
}

.header__detail-typography-1 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.header__detail-id-big {
    font-size: 2rem;
    font-weight: 500;
    line-height: 3rem;
    letter-spacing: 0.01em;
}

.header__detail-project {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125em;
}

.card-body-list {
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.modal-dialog {
    border-radius: 3px;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal-content {
    padding: 20px 40px;
}

.form-label {
    font-size: 14px;
    font-weight: 400;
}

.form-control {
    border-radius: 3px;
}

.file-name {
    cursor: pointer;

    &.disabled {
        cursor: default;
    }
}

.progress-info-span {
    top: 36%;
    padding: 0;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    color: #000000;
}

.oze-button {
    font-size: 13px;
    font-weight: 700;
    border-radius: 3px;
    text-align: center;
    align-items: center;

    &:hover {
        border: 1px solid #2362A2;
        color: #2362A2;
    }

    &.block {
        background: #2362A2;
        color: #FFFFFF;
    }

    &.outline {
        color: #2362A2;
        background: transparent;
        border: 1px solid #2362A2;
    }

    &.outline-delete {
        color: #C52A3A;
        background: transparent;
        border: 1px solid #C52A3A;
    }
}

.import_error_container_bg {
    position: fixed;
    top: 0;
    z-index: 99;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C52A3A;
}

.import_error_container {
    color: white;
    background-color: #C52A3A;

    &.header {
        margin-bottom: 0 !important;
        max-width: 1180px;
    }
}

.import_error_text {
    font-weight: 400;
    font-size: 1em;
}

.import_error_span {
    font-weight: 700;
    color: white;
    font-size: 1em;
}

::-webkit-calendar-picker-indicator {
    background-image: url('../assets/calendarIcon.png');
}

.ico-btn {
    width: fit-content;
    min-width: 131.11px;
    height: 40px;
    margin-top: 4px;
    padding: 2px 5px;
    border: 1px solid #DDDDDD;
    border-radius: 0 3px 3px 0;
    text-wrap: nowrap;
}

.cancel-filter-btn-container {
    top: -14%;
    left: 0%;
}

.cancel-filter-btn {
    width: fit-content;
    min-width: 131.11px;
    margin-top: 4px;
    padding: 2px 5px;
    border: 1px solid #DDDDDD;
    border-radius: 0 3px 3px 0;
    text-wrap: nowrap;
}

.loader-image {
    width: 24px;
    height: auto;
    margin-right: 0.8em;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    &.small {
        width: 20px;
    }
}

.upload-indicator-footer {
    width: fit-content;
    font-size: 14px;
    letter-spacing: 0.015em;

    &.primary {
        font-weight: 700;
    }

    &.secondary {
        font-weight: 400;
    }

    &.span {
        font-weight: 500;
    }
}

button.finisher-disabled {
    background-color: #B5D9F3 !important;
    border: none !important;
    cursor: default !important;
    pointer-events: none !important;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.display {
    display: none;
}

@media only screen and (min-width: 460px) {
    .display {
        display: flex;
    }
}

@media only screen and (max-width: 768px) {
    .welcome-section {
        position: relative;
    }

    .welcome-section::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
    }

    .welcome-section-content {
        z-index: 2;
    }
}

@media only screen and (min-width: 768px) {

    .welcome-section {
        height: 380px;
    }
    .push-items-down {
        margin-top: 92px;
    }
}

@media only screen and (min-width: 1040px) {
    .disable-scroll {
        display: flex;
    }
    
    .welcome-section {
        height: 380px;
    }
    .push-items-down {
        margin-top: 155px;
    }

}

@media only screen and (min-width: 1200px) {
    .base-width {
        width: 1180px;
    }
}

@media only screen and (max-width: 1039px) {
    .import_error_text {
        font-size: 0.8em;
    }

    .import_error_span {
        font-size: 0.8em;
    }
}

.gov-btn-container > .gov-button {
    width: 100%;
    & button {
        width: 100% !important;
    }
}

.native-button--disabled {
    cursor: not-allowed !important;
    background-color: var(--gov-button-primary-outlined-disabled-bg, transparent) !important;
    color: var(--gov-button-primary-outlined-disabled-color, var(--gov-color-primary-400)) !important;
    border-color: var(--gov-button-primary-outlined-disabled-border, var(--gov-color-primary-300)) !important;
}

.icon--medium {
    width: var(--gov-button-m-icon-size, 1.25rem);
    height: var(--gov-button-m-icon-size, 1.25rem);
}

.documentation-finisher-btn {
    width: 100%;
    max-width: 329px;

    & gov-button {
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
    }
    & button{
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
    }
}

.row-revert-x-margin {
    margin-right: calc(-.5* var(--bs-gutter-x)) !important;
    margin-left: calc(-.5* var(--bs-gutter-x)) !important;
}

.row-revert-x-padding {
    padding-right: calc(var(--bs-gutter-x)* .5) !important;
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
}

.border--gov-color-primary-400 {
    --bs-border-color: var(--gov-color-primary-400);
}

.bg--gov-color-primary-100 {
    --gov-color-neutral-white: var(--gov-color-primary-100);
}

.link-create-error {
    background-color: #fdecea;
    border-left: 5px solid #C52A3A; 
}